/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@font-face {
  font-family: 'regular';
  src: url(assets/fonts/Avenir-Roman.otf);
}

@font-face {
  font-family: 'semi-bold';
  src: url(assets/fonts/Avenir-Heavy.ttf);
}

@font-face {
  font-family: 'medium';
  src: url(assets/fonts/Avenir-Medium.ttf);
}

* {
  font-family: 'regular';
}

ion-toolbar {
  --background: #F8F9F9;
}

ion-content {
  --background: #F8F9F9;
}

ion-header {
  ion-toolbar {
    ion-title {
      font-size: 20px;
      font-family: 'semi-bold';
      color: #042C5C;
    }
  }
}




.card_div {
  width: 100%;
  height: 135px;
  border-radius: 10px;
  background: var(--ion-color-primary);
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  img {
    width: 60px;
  }

  .card_no {
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: 'semi-bold';
  }

  .flex_div {
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: space-between;
    width: 100%;

    ion-label {
      color: white;
      font-size: 16px;
      letter-spacing: 0.6px;
    }

    ion-label.account-balance {
      font-size: 2em;
      font-weight: bold;
      margin-left: auto;
    }
  }

  .bottom_abs {
    width: 260px;
    height: 200px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.1), transparent);
    border-radius: 100%;
    position: absolute;
    bottom: -145px;
  }

  .side_abs {
    width: 200px;
    height: 243px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.1), transparent);
    border-radius: 100%;
    position: absolute;
    right: -80px;
    top: -25px;
    transform: rotate(45deg);
  }
}
